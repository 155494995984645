// extracted by mini-css-extract-plugin
export var background_images = "banner-module__background_images___s2etj";
export var banner = "banner-module__banner___4R4QI";
export var banner__container = "banner-module__banner__container___PH9Fc";
export var body_background = "#f8f9fa";
export var card = "banner-module__card___Pv8aa";
export var chip = "banner-module__chip___EOxY2";
export var container = "banner-module__container___6AZPo";
export var content = "banner-module__content___kpjwt";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "banner-module__full_img___mFzHh";
export var full_imgSmall = "banner-module__full_img--small___e5HuJ";
export var gray_21 = "banner-module__gray_21___ECYfw";
export var image_container = "banner-module__image_container___I-Gf7";
export var image_item = "banner-module__image_item___+CkDD";
export var lg = "1200px";
export var logo = "banner-module__logo___ZFeIc";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "banner-module__primary___8eVQw";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "banner-module__title___GystH";
export var typography_h1 = "banner-module__typography_h1___KA4z+";
export var typography_h2 = "banner-module__typography_h2___y-+Hv";
export var typography_h3 = "banner-module__typography_h3___h1OTe";
export var white = "#fff";
export var white_button = "banner-module__white_button___Kslq8";
export var xl = "1536px";
export var xxl = "2500px";