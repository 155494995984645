import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Stack } from '@mui/material';
import { PDFViewer } from '@components';

import * as styles from './banner.module.scss';

const Banner = (/*{ intl: { formatMessage } }*/) => {
  const pdfUrl = '/ConnectingTechPeople.pdf';
  // const pdfUrl = 'https://connectingtechpeople.com/ConnectingTechPeople.pdf';

  return (
    <div className={styles.banner}>
      <Stack alignItems="center">
        <div className={styles.banner__container}></div>
        <PDFViewer pdfUrl={pdfUrl} />
      </Stack>
    </div>
  );
};

Banner.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Banner);
